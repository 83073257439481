<template>
    <div id="supplierscore">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 14.0pt; mso-bidi-font-size: 16.0pt;"><span style="mso-spacerun: yes;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-size: 14.0pt; mso-bidi-font-size: 16.0pt; font-family: 宋体; mso-ascii-font-family: Calibri; mso-ascii-theme-font: minor-latin; mso-fareast-theme-font: minor-fareast; mso-hansi-font-family: Calibri; mso-hansi-theme-font: minor-latin;">供方评价表</span><span lang="EN-US" style="font-size: 14.0pt; mso-bidi-font-size: 16.0pt;"><span style="mso-spacerun: yes;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-US" style="font-size: 12.0pt; font-family: 宋体; mso-ascii-theme-font: minor-fareast; mso-fareast-theme-font: minor-fareast; mso-hansi-theme-font: minor-fareast; mso-bidi-font-family: 宋体; mso-bidi-theme-font: minor-fareast; color: #5b9bd5; mso-themecolor: accent1;">FG-CX-011-01</span></p>
            <div align="center">
            <table class="MsoNormalTable" style="border-collapse: collapse; mso-table-layout-alt: fixed; border: none; mso-border-alt: solid windowtext .5pt; mso-yfti-tbllook: 1184; mso-padding-alt: 0cm 5.4pt 0cm 5.4pt; mso-border-insideh: .5pt solid windowtext; mso-border-insidev: .5pt solid windowtext;" border="1" width="619" cellspacing="0" cellpadding="0">
            <tbody>
            <tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes; page-break-inside: avoid;">
            <td style="width: 194.4pt; border: solid windowtext 1.0pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="3" valign="top" width="259">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">供货单位</span></p>
            </td>
            <td style="width: 270.0pt; border: solid windowtext 1.0pt; border-left: none; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="5" valign="top" width="360">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">地址</span></p>
            <p class="MsoNormal"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 1; page-break-inside: avoid;">
            <td style="width: 194.4pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="3" valign="top" width="259">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">提供产品</span></p>
            </td>
            <td style="width: 270.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="5" valign="top" width="360">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">评价部门</span></p>
            <p class="MsoNormal"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;"><span style="mso-spacerun: yes;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">采购部</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 2; page-break-inside: avoid;">
            <td style="width: 41.4pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" rowspan="2" valign="top" width="55">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">序号</span></p>
            </td>
            <td style="width: 45.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" rowspan="2" valign="top" width="60">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">评价项目</span></p>
            </td>
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" rowspan="2" valign="top" width="288">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">评价内容</span></p>
            </td>
            <td style="width: 99.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="3" valign="top" width="132">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">评价结果</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">备注</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 3; page-break-inside: avoid;">
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">好</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">一般</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">差</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 4; page-break-inside: avoid;">
            <td style="width: 41.4pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" rowspan="3" valign="top" width="55">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">1</span></p>
            </td>
            <td style="width: 45.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" rowspan="3" valign="top" width="60">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">产品质量</span></p>
            </td>
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">有无检测设备器具制度</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 5; page-break-inside: avoid;">
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">检定证书</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 6; page-break-inside: avoid;">
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">产品质量报告或以往质量情况</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 7; page-break-inside: avoid;">
            <td style="width: 41.4pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" rowspan="3" valign="top" width="55">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">2</span></p>
            </td>
            <td style="width: 45.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" rowspan="3" valign="top" width="60">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">技术水平</span></p>
            </td>
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">技术文件齐全</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 8; page-break-inside: avoid;">
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">技术管理制度</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 9; page-break-inside: avoid;">
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">生产设备满足技术水平</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 10; page-break-inside: avoid;">
            <td style="width: 41.4pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" rowspan="3" valign="top" width="55">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">3</span></p>
            </td>
            <td style="width: 45.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" rowspan="3" valign="top" width="60">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">人员配备</span></p>
            </td>
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">人员培训</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 11; page-break-inside: avoid;">
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">人员配备</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 12; page-break-inside: avoid;">
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">质检人员</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 13; page-break-inside: avoid;">
            <td style="width: 41.4pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" rowspan="3" valign="top" width="55">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">4</span></p>
            </td>
            <td style="width: 45.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" rowspan="3" valign="top" width="60">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">质保能力</span></p>
            </td>
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">质保体系</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 14; page-break-inside: avoid;">
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">文件管理</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 15; page-break-inside: avoid;">
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">证书</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 16; page-break-inside: avoid;">
            <td style="width: 41.4pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" rowspan="2" valign="top" width="55">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">5</span></p>
            </td>
            <td style="width: 45.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" rowspan="2" valign="top" width="60">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">商业信誉</span></p>
            </td>
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">交货期</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 17; page-break-inside: avoid;">
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">对不合格品的处置</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 18;">
            <td style="width: 41.4pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="55">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">6</span></p>
            </td>
            <td style="width: 45.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="60">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">价格</span></p>
            </td>
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">与同类产品比较</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 19;">
            <td style="width: 41.4pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="55">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">7</span></p>
            </td>
            <td style="width: 45.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="60">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">售后服务</span></p>
            </td>
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">服务体系、顾客反馈意见处理</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 20; page-break-inside: avoid;">
            <td style="width: 41.4pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" rowspan="4" valign="top" width="55">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">8</span></p>
            </td>
            <td style="width: 45.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" rowspan="4" valign="top" width="60">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">运输</span></p>
            </td>
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">能力</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 21; page-break-inside: avoid;">
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">车辆情况</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 22; page-break-inside: avoid;">
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">司机</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 23; page-break-inside: avoid;">
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">制度</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&radic;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 24;">
            <td style="width: 41.4pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="55">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">9</span></p>
            </td>
            <td style="width: 45.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="60">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">总评分</span></p>
            </td>
            <td style="width: 216.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="288">
            <p class="MsoNormal"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            <p class="MsoNormal"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;"><span style="mso-spacerun: yes;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>98</span><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">分</span></p>
            </td>
            <td style="width: 27.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="36">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 40.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="54">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 31.8pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="42">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            <td style="width: 63.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top" width="84">
            <p class="MsoNormal" style="text-align: center;" align="center"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 25; page-break-inside: avoid;">
            <td style="width: 86.4pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="115">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">评价意见</span></p>
            </td>
            <td style="width: 378.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="6" valign="top" width="504">
            <p class="MsoNormal"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            <p class="MsoNormal"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;"><span style="mso-spacerun: yes;">&nbsp;</span></span></p>
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">建议采用！</span></p>
            <p class="MsoNormal"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;"><span style="mso-spacerun: yes;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">签字：<span lang="EN-US"><span style="mso-spacerun: yes;">&nbsp;&nbsp;&nbsp; </span><span style="mso-spacerun: yes;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun: yes;">&nbsp;&nbsp;&nbsp;</span></span>年<span lang="EN-US"><span style="mso-spacerun: yes;">&nbsp;&nbsp; </span></span>月<span lang="EN-US"><span style="mso-spacerun: yes;">&nbsp;&nbsp; </span></span>日</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 26; page-break-inside: avoid;">
            <td style="width: 86.4pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="115">
            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">评价结论</span></p>
            </td>
            <td style="width: 378.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="6" valign="top" width="504">
            <p class="MsoNormal"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">&nbsp;</span></p>
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">同意！</span></p>
            <p class="MsoNormal"><span lang="EN-US" style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;"><span style="mso-spacerun: yes;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">签字：<span lang="EN-US"><span style="mso-spacerun: yes;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span style="mso-spacerun: yes;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun: yes;">&nbsp;</span></span>年<span lang="EN-US"><span style="mso-spacerun: yes;">&nbsp;&nbsp; </span></span>月<span lang="EN-US"><span style="mso-spacerun: yes;">&nbsp;&nbsp; </span></span>日</span></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 27; mso-yfti-lastrow: yes; page-break-inside: avoid;">
            <td style="width: 86.4pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" valign="top" width="115">
            <p class="MsoNormal"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">供方资料存档</span></p>
            </td>
            <td style="width: 378.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="6" valign="top" width="504">
            <p class="MsoNormal"><strong style="mso-bidi-font-weight: normal;"><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">配套厂家营业执照复印件、检测报告复印件、采购合同<span lang="EN-US">/</span>协议等，</span></strong><span style="font-size: 12.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">（务必包含焊材评价及材质证明资料）</span></p>
            </td>
            </tr>
            <!-- [if !supportMisalignedColumns]-->
            <tr>
            <td style="border: none;" width="55">&nbsp;</td>
            <td style="border: none;" width="60">&nbsp;</td>
            <td style="border: none;" width="144">&nbsp;</td>
            <td style="border: none;" width="144">&nbsp;</td>
            <td style="border: none;" width="36">&nbsp;</td>
            <td style="border: none;" width="54">&nbsp;</td>
            <td style="border: none;" width="42">&nbsp;</td>
            <td style="border: none;" width="84">&nbsp;</td>
            </tr>
            <!--[endif]--></tbody>
            </table>
            </div>
            <p class="MsoNormal" style="text-indent: 14.0pt; mso-char-indent-count: 1.0;"><span style="font-size: 14.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">注</span><span style="font-size: 16.0pt; mso-bidi-font-size: 11.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">：</span><span style="font-size: 12.0pt; font-family: 宋体; mso-fareast-theme-font: minor-fareast;">评分标准如下<span lang="EN-US">1</span>、好<span lang="EN-US">100</span>分，<span lang="EN-US">2</span>、一般<span lang="EN-US">60</span>分，<span lang="EN-US">3</span>、差<span lang="EN-US">30</span>分。<span lang="EN-US">4</span>、总评分为平均得分，超过<span lang="EN-US">60</span>分为合格。</span></p>
    </div>
</template>

<script>
export default {
    name:'SupplierScore'
}
</script>
<style scoped>
 #supplierscore {
    margin: 0 auto;
 }
</style>